<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h1>Login</h1>
      </v-card-title>
      <v-form>
        <v-card-text>
          <v-alert v-for="e in errors.nonFieldErrors" :key="e" type="error">
            {{ e }}
          </v-alert>
          <v-text-field
            v-model="form.username"
            :error-messages="errors.username"
            label="username"
            @keypress.enter="submit"
          />
          <v-text-field
            v-model="form.password"
            :error-messages="errors.password"
            label="password"
            type="password"
            @keypress.enter="submit"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="primary" @click="submit">login</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    form: {
      username: null,
      password: null,
    },
    errors: {},
  }),
  computed: {
    ...mapGetters(['loggedIn']),
    next() {
      return this.$route.query.next
    },
  },
  created() {
    if (this.loggedIn) this.$router.push(this.next || '/')
  },
  methods: {
    ...mapActions(['login']),
    async submit() {
      try {
        this.errors = {}
        await this.login(this.form)
        this.$router.push(this.next || '/')
      } catch (err) {
        console.error(err)
        this.errors = this.$getValidationErrors(err)
      }
    },
  },
}
</script>

<style></style>
